.alert-danger {
  background-color: transparent;
  border-width: 2px;
  border-color: red;
  font-size: 1.4rem;
  color: red; }

.alert-danger a {
  color: red;
  font-weight: 800; }

@media only screen and (max-width: 992px) {
  .btn-link {
    padding: .2 1rem !important; }
  .btn-group {
    height: 40px !important; }
  .btn-group .btn {
    padding: 0 10px !important; } }

.btn {
  font-size: 1.4rem; }

.btn-primary {
  display: inline-block;
  background-color: #4a5e4c;
  border: none;
  padding: 0.5rem 3rem; }

.btn-link {
  color: #4a5e4c;
  padding: 0.5rem 3rem; }

.btn-sm {
  padding: 0 1rem !important; }

.btn-link:hover {
  text-decoration: none;
  color: #8ec44b; }

.btn-primary:hover {
  background-color: #8ec44b; }

.btn-wrapper {
  display: inline-block;
  position: relative;
  top: 0px; }

.btn {
  font-size: 1.4rem; }

.btn-group {
  height: 30px; }

.btn-group .btn {
  padding: 0 10px; }

.btn-secondary {
  background-color: #fff;
  color: #000; }

.btn-yes.focus,
.btn-yes:focus {
  color: #fff;
  background-color: #20d165;
  border-color: #122b40;
  box-shadow: none; }

.btn-no.focus,
.btn-no:focus {
  color: #fff;
  background-color: #d0021b;
  border-color: #122b40;
  box-shadow: none; }

.btn-na.focus,
.btn-na:focus {
  color: #fff;
  background-color: #4ab3f4;
  border-color: #122b40;
  box-shadow: none; }

.btn-secondary:active {
  box-shadow: none; }

.btn-no-padding {
  padding: 5px 0; }

.btn.inactive {
  opacity: 0.6;
  pointer-events: none; }

.card {
  border-radius: .8rem;
  border-style: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }

.loader {
  color: #ffffff;
  display: inline-block;
  font-size: 0.2rem;
  margin: 6px 17px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.loader.dark {
  color: #131313; }

.content-loader {
  color: #a5a5a5;
  font-size: 0.3rem; }

.content-loader-wrapper {
  min-width: 130px; }

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

label {
  font-size: 1.4rem; }

input {
  font-size: 1.4rem !important; }

input.disabled {
  pointer-events: none;
  opacity: 0.6; }

select.form-control {
  min-width: 100px;
  border-radius: 0px;
  padding: 0.5rem;
  font-size: 1.4rem;
  height: 3rem !important; }

.form-group {
  margin: 0; }

.form-control {
  text-align: left;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #c0c0c0;
  background-color: #eeeeee; }

.form-control:focus {
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #ff7300;
  background-color: #eeeeee; }

::placeholder {
  color: #818181 !important; }

textarea {
  font-size: 1.4rem !important; }

.is-invalid {
  font-size: 1.2rem;
  color: red !important; }

.is-invalidError {
  font-size: 1.2rem;
  position: relative;
  bottom: 10px;
  color: red !important; }

.input-group-prepend {
  padding-top: 0.2rem;
  background-color: #fff; }

.form-control.login-input {
  margin-bottom: 1.4rem; }

.form-check {
  padding: 0.5rem 0rem;
  display: inline; }

.btn.btn-link.simple {
  padding: 0.5rem 0rem;
  display: inline; }

.input-group.bottom {
  margin-bottom: 0 !important; }

.login label.is-invalid {
  position: relative;
  bottom: 1rem;
  margin-left: 2.8rem; }

i.is-invalid {
  font-size: 2.4rem; }

.form-group.password i {
  padding-top: 5px;
  position: absolute;
  display: block;
  right: 12px;
  z-index: 100; }

.form-group.password i.correct {
  color: green; }

.form-group.password i.incorrect {
  color: red; }

select {
  background-image: url("./assets/images/drop-down-arrow.png");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right;
  background-size: 12px; }

.v-space {
  margin-top: 1rem; }

.modal-footer {
  display: block; }

.modal-header {
  justify-content: left; }

.modal {
  width: 100vw;
  max-height: calc(100vh - 210px);
  overflow-y: auto; }

.navbar {
  justify-content: unset; }

.primary-navbar {
  justify-content: space-between; }

.secondary-navbar {
  justify-content: unset; }

.no-hover {
  background-color: #c37eff;
  color: #fff; }

.no-hover button {
  color: #fff; }

tr:not(.no-hover):hover {
  background-color: #e4e4e4;
  cursor: pointer; }

.table-hover > tbody > tr.no-hover:hover {
  background-color: #ffffff; }

.table {
  width: 100%; }

.student-row td.name {
  padding-top: 14px; }

@media only screen and (max-width: 576px) {
  .redux-toastr .bottom-right {
    width: 100vw !important;
    min-width: auto !important;
    left: 0;
    right: 0;
    padding: 0;
    bottom: 30px !important;
    margin: 0 !important; } }

.toastr {
  font-size: 14px; }

.rrt-success {
  background-color: #8ec44b !important; }

.toastr button {
  color: #fff; }

.redux-toastr .bottom-right {
  min-width: 500px;
  right: 0;
  padding: 0;
  bottom: 30px;
  margin: 10px 20px; }

h1 {
  display: inline-block;
  width: fit-content; }

h5 {
  font-size: 2rem;
  font-weight: bold; }

h6 {
  font-size: 1.2rem;
  font-weight: bold; }

.deleteWarning {
  font-size: 1.4rem;
  display: inline-block;
  margin-right: 1rem; }

.content-placeholder {
  color: #818181;
  min-width: 100px;
  font-size: 14px;
  display: block; }

select {
  -webkit-appearance: none; }

select::-ms-expand {
  display: block; }

@media only screen and (max-width: 992px) {
  html,
  body {
    font-size: 12px !important; }
  .mobile {
    display: show; }
  .not-mobile {
    display: none; } }

@media only screen and (min-width: 992px) {
  .mobile {
    display: none; }
  .not-mobile {
    display: show; } }

html,
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5; }

.content-area {
  display: block;
  background-color: #f5f5f5;
  height: 100vh; }

.students {
  padding-top: 2rem; }

span.line {
  display: inline-block; }

.student .container {
  padding: 0px !important; }
