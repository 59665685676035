@media only screen and (max-width: 992px){
  .homework {
    max-height: 80px;
  }
  .homework-bar {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: #AFAFAF;
  }
}

.homework {
  border-radius: 0 !important;
  height: 100vh;
  position: relative;
  left: 0;
}