@media only screen and (max-width: 768px){
  .date-created:after {
    content: "Date" !important;
  }
}

.actions {
  display: block !important;
}

.stats span.label {
  font-size: 1.4rem;
}

.stats span.total {
  font-weight: bolder;
  font-size: 1.8rem;
}

.stats span.score {
  color: rgb(111, 111, 111);
  font-size: 1.6rem;
}

.table.studentCard {
  margin-top: -1px;
}

.date-created:after {
  content: "Date Created";
}

.homework-placeholder {
  font-size: 14px;
  color: rgb(111, 111, 111);
}