.primary-navbar {
  background-color: #8ec44b;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: auto;
  min-height: 60px; }

.material-icons {
  color: #fff;
  display: block; }

.dropdown i,
.dropdown img {
  cursor: pointer; }

.dropdown img {
  border-radius: 100%; }

.primary-navbar .dropdown-menu {
  left: auto;
  right: 0px;
  padding: 10px; }

.dropdown-menu .user-email {
  font-size: 1.4rem;
  color: #b8b8b8;
  padding: 1rem; }

.dropdown-menu .link {
  font-size: 1.4rem;
  color: #6d6d6d;
  cursor: pointer; }

.dropdown-menu .link:active {
  color: #fff;
  background-color: #8ec44b; }

.dropdown-menu-backdrop {
  position: fixed;
  z-index: 800;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent; }

.avatar-background {
  padding: 2px;
  text-align: center;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  background-color: #fff; }

.avatar-background img {
  width: 100%; }

.avatar {
  font-size: 3.5rem; }

img.account.avatar {
  width: 42px; }
