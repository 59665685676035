.lightbox {
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 1000 !important;
  position: fixed;
  top: 0px;
  left: 0px;

  background-color: #000;
  opacity: 0.8;
}

span {
  font-size: 3.5rem;
  line-height: 2.5rem;
}

.actions {
  margin-right: 0px;
  margin-bottom: 10px;
}