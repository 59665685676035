.alert-danger {
  background-color: transparent;
  border-width: 2px;
  border-color: red;
  font-size: 1.4rem;
  color: red; }

.alert-danger a {
  color: red;
  font-weight: 800; }

@media only screen and (max-width: 992px) {
  .btn-link {
    padding: .2 1rem !important; }
  .btn-group {
    height: 40px !important; }
  .btn-group .btn {
    padding: 0 10px !important; } }

.btn {
  font-size: 1.4rem; }

.btn-primary {
  display: inline-block;
  background-color: #4a5e4c;
  border: none;
  padding: 0.5rem 3rem; }

.btn-link {
  color: #4a5e4c;
  padding: 0.5rem 3rem; }

.btn-sm {
  padding: 0 1rem !important; }

.btn-link:hover {
  text-decoration: none;
  color: #8ec44b; }

.btn-primary:hover {
  background-color: #8ec44b; }

.btn-wrapper {
  display: inline-block;
  position: relative;
  top: 0px; }

.btn {
  font-size: 1.4rem; }

.btn-group {
  height: 30px; }

.btn-group .btn {
  padding: 0 10px; }

.btn-secondary {
  background-color: #fff;
  color: #000; }

.btn-yes.focus,
.btn-yes:focus {
  color: #fff;
  background-color: #20d165;
  border-color: #122b40;
  box-shadow: none; }

.btn-no.focus,
.btn-no:focus {
  color: #fff;
  background-color: #d0021b;
  border-color: #122b40;
  box-shadow: none; }

.btn-na.focus,
.btn-na:focus {
  color: #fff;
  background-color: #4ab3f4;
  border-color: #122b40;
  box-shadow: none; }

.btn-secondary:active {
  box-shadow: none; }

.btn-no-padding {
  padding: 5px 0; }

.btn.inactive {
  opacity: 0.6;
  pointer-events: none; }

.card {
  border-radius: .8rem;
  border-style: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }

.loader {
  color: #ffffff;
  display: inline-block;
  font-size: 0.2rem;
  margin: 6px 17px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.loader.dark {
  color: #131313; }

.content-loader {
  color: #a5a5a5;
  font-size: 0.3rem; }

.content-loader-wrapper {
  min-width: 130px; }

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

label {
  font-size: 1.4rem; }

input {
  font-size: 1.4rem !important; }

input.disabled {
  pointer-events: none;
  opacity: 0.6; }

select.form-control {
  min-width: 100px;
  border-radius: 0px;
  padding: 0.5rem;
  font-size: 1.4rem;
  height: 3rem !important; }

.form-group {
  margin: 0; }

.form-control {
  text-align: left;
  padding: 0.5rem;
  padding-right: 2rem;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #c0c0c0;
  background-color: #eeeeee; }

.form-control:focus {
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #ff7300;
  background-color: #eeeeee; }

::-webkit-input-placeholder {
  color: #818181 !important; }

::-ms-input-placeholder {
  color: #818181 !important; }

::placeholder {
  color: #818181 !important; }

textarea {
  font-size: 1.4rem !important; }

.is-invalid {
  font-size: 1.2rem;
  color: red !important; }

.is-invalidError {
  font-size: 1.2rem;
  position: relative;
  bottom: 10px;
  color: red !important; }

.input-group-prepend {
  padding-top: 0.2rem;
  background-color: #fff; }

.form-control.login-input {
  margin-bottom: 1.4rem; }

.form-check {
  padding: 0.5rem 0rem;
  display: inline; }

.btn.btn-link.simple {
  padding: 0.5rem 0rem;
  display: inline; }

.input-group.bottom {
  margin-bottom: 0 !important; }

.login label.is-invalid {
  position: relative;
  bottom: 1rem;
  margin-left: 2.8rem; }

i.is-invalid {
  font-size: 2.4rem; }

.form-group.password i {
  padding-top: 5px;
  position: absolute;
  display: block;
  right: 12px;
  z-index: 100; }

.form-group.password i.correct {
  color: green; }

.form-group.password i.incorrect {
  color: red; }

select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAQAAAD2e2DtAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCAcAKx/Bh5PNAAAD4klEQVR42u3S2ZUcNxBFQfDQMXmmHM/kmfQhUZyle7oWAIklohwo5LulAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB3/ShR/sz+CdK8/Sx/lR/lj+z/IMVbiZ+lSGBTbyVK+VlKkcCO3kqU8isACezmv/l/ByCBnfw///sAJLCLd/N/DEACO/gw/+cAJLC6T/N/DUACK/sy/6MAJLCqB/M/DkACK3o4/7MAJLCaJ/M/D0ACK3k6/3cBSGAV38z/fQASWMG3878KQAKzezH/6wAkMLOX8x8JQAKzOjD/sQAkMKND8x8NQAKzOTj/8QAkMJPD858JQAKzODH/uQAkMINT858NQAKjOzn/+QAkMLLT818JQAKjujD/tQAkMKJL818NQAKjuTj/9QAkMJLL898JQAKjuDH/vQAkMIJb898NQALZbs5/PwAJZLo9f40AJJClwvx1ApBAhirz1wpAAr1Vmr9eABLoqdr8NQOQQC8V568bgAR6qDp/7QAk0Frl+esHIIGWqs/fIgAJtNJg/jYBSKCFJvO3CkACtTWav10AEqip2fwtA5BALQ3nbxuABGpoOn/rACRwV+P52wcggTuaz98jAAlc1WH+PgFI4Iou8/cKQAJndZq/XwASOKPb/D0DkMBRHefvG4AEjug6f+8AJPBK5/n7ByCB73SfPyMACTyTMH9OABJ4JGX+rAAk8FnS/HkBSOC9tPkzA5DAL4nz54vy9+bfxuNLwPyllJ0TiOzTj2LPBCL77CPZL4HIPvlo9kogss89on0SiOxTj2qPBCL7zCNbP4HIPvHo1k4gss87g3UTiOzTzmLNBCL7rDNZL4HIPuls1kogss85o3USiOxTzmqNBCL7jDObP4HIPuHs5k4gss+3gnkTiOzTrWLOBCL7bCuZL4HIPtlq5kogss+1onkSiOxTrWqOBCL7TCsbP4HIPtHqxk4gss+zg3ETiOzT7GLMBCL7LDsZL4HIPsluxkogss+xo3ESiOxT7GqMBCL7DDvLTyCyT7C73AQi+/lkJhDZT+dfOQlE9rP5rX8Ckf1kPuqbQGQ/l6/6JRDZT+WxPglE9jN5rn0Ckf1Evtc2gch+Hq+1SyCyn8YxbRKI7GdxXP0EIvtJnFM3gch+DufVSyCyn8I1dRKI7Gdw3f0EIvsJ3HMvgcj+fe67nkBk/zp1XEsgsn+bes4nENm/TF3nEojs36W+4wlE9q/SxrEEIvs3aed1ApH9i7QV5t9dmH93Yf7dhfl3F+bfXZh/d2H+3YX5AQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC7/gH+TvBowiBwTgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wOC0wN1QwMDo0MzozMSswMjowMO59kGIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDgtMDdUMDA6NDM6MzErMDI6MDCfICjeAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right;
  background-size: 12px; }

.v-space {
  margin-top: 1rem; }

.modal-footer {
  display: block; }

.modal-header {
  -webkit-justify-content: left;
          justify-content: left; }

.modal {
  width: 100vw;
  max-height: calc(100vh - 210px);
  overflow-y: auto; }

.navbar {
  -webkit-justify-content: unset;
          justify-content: unset; }

.primary-navbar {
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.secondary-navbar {
  -webkit-justify-content: unset;
          justify-content: unset; }

.no-hover {
  background-color: #c37eff;
  color: #fff; }

.no-hover button {
  color: #fff; }

tr:not(.no-hover):hover {
  background-color: #e4e4e4;
  cursor: pointer; }

.table-hover > tbody > tr.no-hover:hover {
  background-color: #ffffff; }

.table {
  width: 100%; }

.student-row td.name {
  padding-top: 14px; }

@media only screen and (max-width: 576px) {
  .redux-toastr .bottom-right {
    width: 100vw !important;
    min-width: auto !important;
    left: 0;
    right: 0;
    padding: 0;
    bottom: 30px !important;
    margin: 0 !important; } }

.toastr {
  font-size: 14px; }

.rrt-success {
  background-color: #8ec44b !important; }

.toastr button {
  color: #fff; }

.redux-toastr .bottom-right {
  min-width: 500px;
  right: 0;
  padding: 0;
  bottom: 30px;
  margin: 10px 20px; }

h1 {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

h5 {
  font-size: 2rem;
  font-weight: bold; }

h6 {
  font-size: 1.2rem;
  font-weight: bold; }

.deleteWarning {
  font-size: 1.4rem;
  display: inline-block;
  margin-right: 1rem; }

.content-placeholder {
  color: #818181;
  min-width: 100px;
  font-size: 14px;
  display: block; }

select {
  -webkit-appearance: none; }

select::-ms-expand {
  display: block; }

@media only screen and (max-width: 992px) {
  html,
  body {
    font-size: 12px !important; }
  .mobile {
    display: show; }
  .not-mobile {
    display: none; } }

@media only screen and (min-width: 992px) {
  .mobile {
    display: none; }
  .not-mobile {
    display: show; } }

html,
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5; }

.content-area {
  display: block;
  background-color: #f5f5f5;
  height: 100vh; }

.students {
  padding-top: 2rem; }

span.line {
  display: inline-block; }

.student .container {
  padding: 0px !important; }


.background {
  background-color: #475253;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
}

.login.card {
  padding-top: 40px;
  padding-bottom: 40px;
}

.container .login, .container .register {
  margin-top: 10px;
}

.login .logo {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.login i {
  color: rgb(82, 82, 82);
  margin-right: 5px;
  font-size: 2.4rem;
  margin-top: 2px;
}

.login .password-util {
  height: 30px;
  margin-bottom: 10px;
  margin-top: 10px
}

.google-signin img {
  height: 2rem;
  margin-right: 0.5rem;
}
.loginheader {
  width: 100%;
  text-align: center;
}

.loginheader span,
.loginheader a {
  font-size: 1.4rem;
  color: #fff;
}

.loginheader a {
  margin-left: 1rem;
}

.loginheader a:hover {
  text-decoration: underline;
  margin-left: 1rem;
}

.login-error {
  font-size: 1.4rem;
  color: red;
  margin-bottom: 5px;
}

.btn-content-wrapper {
  margin-right: 3rem;
  height: 21px;
}

.wizard {
  width: 100%;
  font-size: 1.4rem;
  display: block;
  position: relative;
  z-index: 1000;
  left: 0px;
  margin-bottom: 10px;
}

.wizard.confirmPassword {
  bottom: 30px;
}

.wizard .col {
  padding: 0px 0px;
  margin: 0px;
}

.wizard ul {
  list-style-type: none;
  padding-left: 1rem;
}

.wizard i {
  display: inline-block;
  height: 25px;
}

.wizard li {
  display: -webkit-flex;
  display: flex;
  padding: 0.25rem;
  -webkit-align-items: center;
          align-items: center;
}

.wizard span {
  display: inline-block;
  font-size: 1.4rem;
  height: 25px;
}

.wizard li.complete,
.wizard li.complete i {
  color: green;
}

.wizard li.incomplete,
.wizard li.incomplete i {
  color: red;
}

.forgotpassword {
  color: #475253
}

.forgotpassword:hover {
  color: #8ec44b
}
.primary-navbar {
  background-color: #8ec44b;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: auto;
  min-height: 60px; }

.material-icons {
  color: #fff;
  display: block; }

.dropdown i,
.dropdown img {
  cursor: pointer; }

.dropdown img {
  border-radius: 100%; }

.primary-navbar .dropdown-menu {
  left: auto;
  right: 0px;
  padding: 10px; }

.dropdown-menu .user-email {
  font-size: 1.4rem;
  color: #b8b8b8;
  padding: 1rem; }

.dropdown-menu .link {
  font-size: 1.4rem;
  color: #6d6d6d;
  cursor: pointer; }

.dropdown-menu .link:active {
  color: #fff;
  background-color: #8ec44b; }

.dropdown-menu-backdrop {
  position: fixed;
  z-index: 800;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent; }

.avatar-background {
  padding: 2px;
  text-align: center;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  background-color: #fff; }

.avatar-background img {
  width: 100%; }

.avatar {
  font-size: 3.5rem; }

img.account.avatar {
  width: 42px; }

.lightbox {
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 1000 !important;
  position: fixed;
  top: 0px;
  left: 0px;

  background-color: #000;
  opacity: 0.8;
}

span {
  font-size: 3.5rem;
  line-height: 2.5rem;
}

.actions {
  margin-right: 0px;
  margin-bottom: 10px;
}
.tutorial-wrapper {
  position: absolute;
  display: block;
  z-index: 9999;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 400px;
}

.tutorial-wrapper.rightAlignment {
  right: 20px;
  margin-left: 20px;
  top: 20px;
}

.homework .tutorial-wrapper.rightAlignment {
  top: auto;
}

.student-table .tutorial-wrapper.rightAlignment {
  top: auto;
}

.tutorial-wrapper.leftAlignment {
  margin-right: 20px;
  left: 60px;
  top: 30px;

}


.circle {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #FF7300;
}
.arrowLine {
  display: block;
  max-width: .3rem;
  margin-left: 8px;
  border-style: solid;
  border-color: #FF7300;
  border-width: 0 1px 0 1px;
  background-color: #FF7300;
  height: 10rem;
  padding: 0;
}

.arrow {
  width: 2rem;
  display: inline-block;
}

.arrowWrapper {
  height: 11rem;
}

.arrowWrapper.right {
  text-align: right;
}

.arrowWrapper.left {
  text-align: left;
  margin-left: 20px;

}

.arrowWrapper.mid-right {
  text-align: right;
  margin-right: 100px;
}

.arrowWrapper.center {
  text-align: center;
}



.tutorial-header {
  padding: .5rem 1rem;
  background-color: #FF7300;
  color: #fff;
  border-radius: 8px 8px 0 0;
  font-size: 1.4rem;
}

.tutorial-header .title {
  display: inline-block;
}

.card.tutorial {
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #FF7300;
}

.tutorial .body {
  color: #000;
  padding: .5rem 1rem;
  font-size: 1.4rem;
}


.tutorial-header button span {
  color: #fff;
  opacity: 1;
  font-size: 2.4rem;
  line-height: 2.1rem;
  border: none;
}

.tutorial-header button {
  margin-left: 5px;
  opacity: 1;

}

.secondary-navbar {
  position: relative;
  z-index: 999;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  max-height: 80px;
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: .6rem 0
}

.secondary-navbar.classes {
  z-index: 999 !important;

}

.secondary-navbar.homework {
  z-index: 998 !important;
  
}

.secondary-navbar span {
  height: 80%;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #373737;
  opacity: 0.3;
}


.classes-placeholder{
  font-size: 1.2rem;
  white-space:nowrap;

}
.actions i.material-icons {
  color: #000;
}

i.delete:hover {
  color: red;
  transition-duration: 0.2s;
  cursor: pointer;
}



@media only screen and (max-width: 576px){
  .student-table .fname:after {
    content: "F Name" !important;
  }
  
  .student-table .lname:after {
    content: "L Name" !important; 
  }
  .student-table .checker:after {
    content: "Check" !important;
  }
  .students .card {
    margin-left: 0 !important;
    margin: 0 1rem !important;
  }
  .card-body.student{
    padding: 15px 0;
  }

  

}

.table thead th {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 0px;
}

tr {
  font-size: 14px;
}

table {
  margin-top: 16px;
}

.student-table .fname:after {
  content: "First Name";
}

.student-table .lname:after {
  content: "Last Name";
}
.student-table .checker:after {
  content: "Homework Checker";
}

.students .card {
  margin-left: 15px;
}

.student .container-fluid {
  padding: 0px;

}

.homework-details {
  margin-top: 1rem;
  font-size: 14px;
}

.student .header-content {
  padding: 15px 15px 0 15px;
}
@media only screen and (max-width: 768px){
  .date-created:after {
    content: "Date" !important;
  }
}

.actions {
  display: block !important;
}

.stats span.label {
  font-size: 1.4rem;
}

.stats span.total {
  font-weight: bolder;
  font-size: 1.8rem;
}

.stats span.score {
  color: rgb(111, 111, 111);
  font-size: 1.6rem;
}

.table.studentCard {
  margin-top: -1px;
}

.date-created:after {
  content: "Date Created";
}

.homework-placeholder {
  font-size: 14px;
  color: rgb(111, 111, 111);
}
@media only screen and (max-width: 992px){
  .homework {
    max-height: 80px;
  }
  .homework-bar {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: #AFAFAF;
  }
}

.homework {
  border-radius: 0 !important;
  height: 100vh;
  position: relative;
  left: 0;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

