.tutorial-wrapper {
  position: absolute;
  display: block;
  z-index: 9999;
  width: max-content;
  max-width: 400px;
}

.tutorial-wrapper.rightAlignment {
  right: 20px;
  margin-left: 20px;
  top: 20px;
}

.homework .tutorial-wrapper.rightAlignment {
  top: auto;
}

.student-table .tutorial-wrapper.rightAlignment {
  top: auto;
}

.tutorial-wrapper.leftAlignment {
  margin-right: 20px;
  left: 60px;
  top: 30px;

}


.circle {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #FF7300;
}
.arrowLine {
  display: block;
  max-width: .3rem;
  margin-left: 8px;
  border-style: solid;
  border-color: #FF7300;
  border-width: 0 1px 0 1px;
  background-color: #FF7300;
  height: 10rem;
  padding: 0;
}

.arrow {
  width: 2rem;
  display: inline-block;
}

.arrowWrapper {
  height: 11rem;
}

.arrowWrapper.right {
  text-align: right;
}

.arrowWrapper.left {
  text-align: left;
  margin-left: 20px;

}

.arrowWrapper.mid-right {
  text-align: right;
  margin-right: 100px;
}

.arrowWrapper.center {
  text-align: center;
}



.tutorial-header {
  padding: .5rem 1rem;
  background-color: #FF7300;
  color: #fff;
  border-radius: 8px 8px 0 0;
  font-size: 1.4rem;
}

.tutorial-header .title {
  display: inline-block;
}

.card.tutorial {
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #FF7300;
}

.tutorial .body {
  color: #000;
  padding: .5rem 1rem;
  font-size: 1.4rem;
}


.tutorial-header button span {
  color: #fff;
  opacity: 1;
  font-size: 2.4rem;
  line-height: 2.1rem;
  border: none;
}

.tutorial-header button {
  margin-left: 5px;
  opacity: 1;

}