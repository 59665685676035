
.secondary-navbar {
  position: relative;
  z-index: 999;
  width: 100%;
  display: flex;
  max-height: 80px;
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: .6rem 0
}

.secondary-navbar.classes {
  z-index: 999 !important;

}

.secondary-navbar.homework {
  z-index: 998 !important;
  
}

.secondary-navbar span {
  height: 80%;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #373737;
  opacity: 0.3;
}


.classes-placeholder{
  font-size: 1.2rem;
  white-space:nowrap;

}