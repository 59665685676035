@media only screen and (max-width: 576px){
  .student-table .fname:after {
    content: "F Name" !important;
  }
  
  .student-table .lname:after {
    content: "L Name" !important; 
  }
  .student-table .checker:after {
    content: "Check" !important;
  }
  .students .card {
    margin-left: 0 !important;
    margin: 0 1rem !important;
  }
  .card-body.student{
    padding: 15px 0;
  }

  

}

.table thead th {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 0px;
}

tr {
  font-size: 14px;
}

table {
  margin-top: 16px;
}

.student-table .fname:after {
  content: "First Name";
}

.student-table .lname:after {
  content: "Last Name";
}
.student-table .checker:after {
  content: "Homework Checker";
}

.students .card {
  margin-left: 15px;
}

.student .container-fluid {
  padding: 0px;

}

.homework-details {
  margin-top: 1rem;
  font-size: 14px;
}

.student .header-content {
  padding: 15px 15px 0 15px;
}