
.background {
  background-color: #475253;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
}

.login.card {
  padding-top: 40px;
  padding-bottom: 40px;
}

.container .login, .container .register {
  margin-top: 10px;
}

.login .logo {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.login i {
  color: rgb(82, 82, 82);
  margin-right: 5px;
  font-size: 2.4rem;
  margin-top: 2px;
}

.login .password-util {
  height: 30px;
  margin-bottom: 10px;
  margin-top: 10px
}

.google-signin img {
  height: 2rem;
  margin-right: 0.5rem;
}
.loginheader {
  width: 100%;
  text-align: center;
}

.loginheader span,
.loginheader a {
  font-size: 1.4rem;
  color: #fff;
}

.loginheader a {
  margin-left: 1rem;
}

.loginheader a:hover {
  text-decoration: underline;
  margin-left: 1rem;
}

.login-error {
  font-size: 1.4rem;
  color: red;
  margin-bottom: 5px;
}

.btn-content-wrapper {
  margin-right: 3rem;
  height: 21px;
}

.wizard {
  width: 100%;
  font-size: 1.4rem;
  display: block;
  position: relative;
  z-index: 1000;
  left: 0px;
  margin-bottom: 10px;
}

.wizard.confirmPassword {
  bottom: 30px;
}

.wizard .col {
  padding: 0px 0px;
  margin: 0px;
}

.wizard ul {
  list-style-type: none;
  padding-left: 1rem;
}

.wizard i {
  display: inline-block;
  height: 25px;
}

.wizard li {
  display: flex;
  padding: 0.25rem;
  align-items: center;
}

.wizard span {
  display: inline-block;
  font-size: 1.4rem;
  height: 25px;
}

.wizard li.complete,
.wizard li.complete i {
  color: green;
}

.wizard li.incomplete,
.wizard li.incomplete i {
  color: red;
}

.forgotpassword {
  color: #475253
}

.forgotpassword:hover {
  color: #8ec44b
}